
import { ConsentFormQuestionsEntity } from '@/common/types/ConsentFormQuestions.type';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import SignaturePad from 'signature_pad';

@Options({
	name: 'WaiverQuestion',
	emits: ['onAgree', 'onSubmit'],
})
export default class FormQuestion extends Vue {
	@Prop() form!: ConsentFormQuestionsEntity;
	@Prop({ default: false }) getSignature!: boolean;

	signaturePad: SignaturePad | null = null;
	disableSubmit = false;

	created() {
		this.disableSubmit = this.getSignature;
	}

	mounted() {
		if (this.getSignature) {
			const canvas = document.querySelector('canvas');
			if (canvas) {
				canvas.width = document.getElementById('consentFormsWrapper')!.getBoundingClientRect().width - 48;
				canvas.height = 300;
				this.signaturePad = new SignaturePad(canvas);
				this.signaturePad.addEventListener('endStroke', () => {
					this.disableSubmit = this.signaturePad?.isEmpty() as boolean;
				});
			}
		}
	}

	captureSignature() {
		this.$emit('onSubmit', { signature: this.signaturePad?.toDataURL().replace('data:image/png;base64,', ''), questionKey: this.form.ConsentFormQuestionKey });
	}

	clearSigPad() {
		this.signaturePad?.clear();
		this.disableSubmit = true;
	}
}
